import React, { useState } from "react";
import { Link } from "gatsby";
import "../styles/hero.scss";

import prva from "../images/prva_nagrada.png";
import druga from "../images/druga_nagrada.png";
import treca from "../images/treca_nagrada.png";
import sve from "../images/sve_skupa.png";

const Hero = () => {
	const [isMobile, setIsMobile] = useState(0);
	if (typeof window !== "undefined")
		setIsMobile(window.innerWidth < 460 ? true : false);

	if (isMobile)
		return (
			<section className="hero">
				<div className="container">
					<div className="text">
						<h1>Nagradna igra povodom dana očeva!</h1>
						<h3>1.3. - 31. 3. 2022.</h3>
						<p>
							Kupi 2 Dove MEN+CARE proizvoda ili 1 Dove MEN+CARE
							proizvod te pošalji SMS sadržaja DANOCEVA, broj
							računa, ime i prezime, adresa (ulica i kućni broj,
							poštanski broj i mjesto) na 60777 (2,40kn /SMS) ili
							se prijavi na ovoj stranici.
						</p>
						<Link to="winners">
							<span
								className="button"
							>
								Provjeri dobitnike
							</span>
						</Link>
					</div>

					<div className="prizes">
						<div className="full">
							<img src={prva} />
						</div>
						<div className="half">
							<img src={druga} />
						</div>
						<div className="half">
							<img src={treca} />
						</div>
					</div>
				</div>
			</section>
		);
	if (!isMobile)
		return (
			<section className="hero">
				<div className="container">
					<div className="text">
						<h1>Nagradna igra povodom dana očeva!</h1>
						<h3>1.3. - 31. 3. 2022.</h3>
						<p>
							Kupi 2 Dove MEN+CARE proizvoda ili 1 Dove MEN+CARE
							proizvod te pošalji SMS sadržaja DANOCEVA, broj
							računa, ime i prezime, adresa (ulica i kućni broj,
							poštanski broj i mjesto) na 60777 (2,40kn /SMS) ili
							se prijavi na ovoj stranici.
						</p>
						<Link to="winners">
							<span
								className="button"
							>
								Provjeri dobitnike
							</span>
						</Link>
					</div>

					<div className="prizes">
						<div className="full">
							<img src={sve} />
						</div>
					</div>
				</div>
			</section>
		);
};

export default Hero;
